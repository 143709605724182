<template>
  <div class="main-content">
    <page-title name="Электронная приемная" route_name="attractions"/>
    <div class="section">
      <div class="row">
        <div class="section">
          <div class="pst-block">
            <div class="checkout">
              <div class="pst-block-main">
                <div class="post-content">
                  <form @submit.prevent="appeal_submit" class="login-customer-block" method="post">
                    <p>Уважаемые пользователи!<br>Просим вас внимательно ознакомиться с порядком приема и рассмотрения обращений в электронном виде. <a href="">Порядок приема и правила рассмотрения обращений граждан</a><br>* - поля, обязательные для заполнения</p>
                    <div class="checkout-line">
                      <div class="col-row">
                        <div class="col-half sm-col-half mb-ls-full">
                          <label class="label-tp-1">Тип обращения *</label>
                          <select class="input-tp-1 reports-input-type" required="required" title="Тип обращения" v-model="appeal_type">
                            <option :value="null" disabled selected>Выберите тип обращения</option>
                            <option :key="index" :value="item.id" v-for="(item, index) in types_appeal">{{item.name}}</option>
                          </select>
                        </div>
                        <div class="col-half sm-col-half mb-ls-full">
                          <label class="label-tp-1">Получатель *</label>
                          <select class="input-tp-1 reports-input-type" required="required" title="Получатель" v-model="selected_receiver">
                            <option :value="null" selected disabled>Выберите получателя</option>
                            <option value="начальник">Обращение к руководителю</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-row">
                        <div class="col-half sm-col-half mb-ls-full">
                          <label class="label-tp-1">Фамилия *</label>
                          <input class="input-tp-1" name="surname" pattern="^[a-zA-Zа-яА-Я-]{2,25}" placeholder="Фамилия*" required="required" title="Иванов" type="text" v-model="surname">
                        </div>
                        <div class="col-half sm-col-half mb-ls-full">
                          <label class="label-tp-1">Имя *</label>
                          <input class="input-tp-1" name="name" pattern="^[a-zA-Zа-яА-Я-]{2,25}" placeholder="Имя*" required="required" title="Иван" type="text" v-model="name">
                        </div>
                      </div>
                      <div class="col-row">
                        <div class="col-half sm-col-half mb-ls-full">
                          <label class="label-tp-1">Отчество *</label>
                          <input class="input-tp-1" name="patronymic" pattern="^[a-zA-Zа-яА-Я-]{2,25}" placeholder="Отчество*" required="required" title="Иванович" type="text" v-model="patronymic">
                        </div>
                      </div>
                    </div>
                    <div class="checkout-line">
                      <div class="col-row">
                        <div class="col-half sm-col-half mb-ls-full">
                          <label class="label-tp-1">Город</label>
                          <input class="input-tp-1" name="city" pattern="^[a-zA-Zа-яА-Я -]{2,30}" placeholder="Город" title="Москва" type="text" v-model="city">
                        </div>
                        <div class="col-half sm-col-half mb-ls-full">
                          <label class="label-tp-1">Улица</label>
                          <input class="input-tp-1" name="street" pattern="^[a-zA-Zа-яА-Я0-9 -]{2,30}" placeholder="Улица" title="Шамиля" type="text" v-model="street">
                        </div>
                      </div>
                      <div class="col-row">
                        <div class="col-one-quarter sm-col-half mb-ls-full">
                          <label class="label-tp-1">Дом</label>
                          <input class="input-tp-1" name="home" pattern="^[0-9]{1,4}" placeholder="Дом" title="51" type="text" v-model="home">
                        </div>
                        <div class="col-one-quarter sm-col-half mb-ls-full">
                          <label class="label-tp-1">Строение</label>
                          <input class="input-tp-1" name="building" pattern="^[0-9]{1,4}" placeholder="Строение" title="6" type="text" v-model="building">
                        </div>
                        <div class="col-one-quarter sm-col-half mb-ls-full">
                          <label class="label-tp-1">Корпус</label>
                          <input class="input-tp-1" name="corps" pattern="^[a-zA-Zа-яА-Я]{1,3}" placeholder="Корпус" title="А" type="text" v-model="corps">
                        </div>
                        <div class="col-one-quarter sm-col-half mb-ls-full">
                          <label class="label-tp-1">Квартира</label>
                          <input class="input-tp-1" name="flat" pattern="^[0-9]{1,3}" placeholder="Квартира" title="21" type="text" v-model="flat">
                        </div>
                      </div>
                      <div class="col-row">
                        <div class="col-half sm-col-half mb-ls-full">
                          <label class="label-tp-1">E-mail *</label>
                          <input class="input-tp-1" inputmode="email" name="email" pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,})*$" placeholder="Email*" required="required" title="example@mail.com" type="text" v-model="email">
                        </div>
                        <div class="col-half sm-col-half mb-ls-full">
                          <label class="label-tp-1">Телефон *</label>
                          <input class="input-tp-1" inputmode="tel" name="phone" pattern="^\+7{1}\d{3}\d{3}\d{2}\d{2}$" placeholder="Телефон*" required="required" title="+70123456789" type="text" v-model="tel">
                        </div>
                      </div>
                      <div class="col-row">
                        <div class="col-half sm-col-half mb-ls-full">
                          <label class="label-tp-1">Индекс</label>
                          <input class="input-tp-1" name="index" pattern="^[0-9]{6}" placeholder="Индекс" title="367000" type="text" v-model="index">
                        </div>
                      </div>
                    </div>
                    <div class="checkout-line">
                      <div class="col-row">
                        <div class="col">
                          <label class="label-tp-1">Суть вопроса *</label>
                          <textarea class="textarea-tp-1" name="question_title" placeholder="Суть вопроса*" required="required" title="Суть вопроса*" v-model="question_title"></textarea>
                        </div>
                      </div>
                      <div class="col-row">
                        <div class="col">
                          <label class="label-tp-1">Содержание обращения *</label>
                          <textarea class="textarea-tp-1" name="question" placeholder="Содержание обращения*" required="required" title="Содержание обращения*" v-model="question"></textarea>
                        </div>
                      </div>
                      <div class="col-row">
                        <div class="col">
                          <label class="label-tp-1">В какие органы государственной власти вы обращались и когда</label>
                          <textarea class="textarea-tp-1" name="before_question" placeholder="В какие органы государственной власти вы обращались и когда" title="В какие органы государственной власти вы обращались и когда" v-model="before_question"></textarea>
                        </div>
                      </div>
                      <div class="col-row">
                        <div class="col">
                          <label class="label-tp-1">Прикрепить файлы</label>
                          <input @change="processFile()" class="input-tp-1" id="file" multiple="multiple" name="file" ref="files" type="file">
                        </div>
                      </div>
                    </div>
                    <div class="checkout-line">
                      <div class="remember">
                        <button class="btn-7">Отправить обращение</button>
                        Нажимая на кнопку, вы даете согласие на обработку персональных данных и
                        <a href="">соглашаетесь с политикой конфиденциальности.</a>
                      </div>
                    </div>
                  </form>
                  <div class="your-order-block" v-if="appeal_list.length">
                    <div class="title-blc-5">
                      <h3 class="title-27">Статистика</h3>
                    </div>
                    <table class="order-table">
                      <thead>
                      <tr>
                        <th style="white-space: nowrap">Тип обращения</th>
                        <th style="white-space: nowrap">Общее кол-во принятых обращений</th>
                        <th style="white-space: nowrap">Не рассмотренные</th>
                        <th style="white-space: nowrap">На рассмотрении</th>
                        <th style="white-space: nowrap">Рассмотрено</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr :key="index" class="list-first" v-for="(appeal, index) in appeal_list">
                        <td>{{ appeal.name }}</td>
                        <td>{{ appeal.no_watching + appeal.on_watching + appeal.watching }}</td>
                        <td>{{ appeal.no_watching }}</td>
                        <td>{{ appeal.on_watching }}</td>
                        <td>{{ appeal.watching }}</td>
                      </tr>
                      <tr>
                        <td class="bd" colspan="5">
                          <form @submit.prevent="checkAppeal()">
                            <div class="col-row">
                              <div class="col">
                                <label class="label-tp-1">Поиск обращения по ID</label>
                                <input class="input-tp-1" name="appeal_check" placeholder="Поиск обращения по ID" required="required" type="search" v-model="appeal_input">
                              </div>
                            </div>
                            <div class="col-row" style="margin-top: 10px">
                              <div class="col">
                                <button class="btn-7">Поиск</button>
                              </div>
                            </div>
                          </form>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <div class="appeal-results" v-if="appeal_info">
                      <span class="h4-text">{{appeal_result_title}}</span>
                      <p v-if="appeal_name !== null">
                        <span>ФИО:</span>
                        {{appeal_name}}
                      </p>
                      <p v-if="appeal_status !== null">
                        <span>Статус:</span>
                        {{appeal_status}}
                      </p>
                      <p v-if="appeal_id !== null">
                        <span>ID:</span>
                        {{appeal_id}}
                      </p>
                      <p v-if="appeal_date !== null">
                        <span>Дата:</span>
                        {{appeal_date}}
                      </p>
                      <p v-if="appeal_text !== null">
                        <span>Текст:</span>
                        {{appeal_text}}
                      </p>
                      <p v-if="appeal_comment !== null">
                        <span>Комментарий {{ $store.state.school_data.school.category_id === 2 ? 'садика' : 'школы' }}:</span>
                        {{appeal_comment}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '../components/layouts/PageTitle.vue';
import axios from 'axios';

export default {
  name: 'Appeal',
  data () {
    return {
      appeal_type: null,
      selected_receiver: null,
      surname: null,
      name: null,
      patronymic: null,
      city: null,
      street: null,
      home: null,
      building: null,
      corps: null,
      flat: null,
      email: null,
      tel: null,
      index: null,
      question_title: null,
      question: null,
      before_question: null,
      files: '',
      appeal_input: null, //поле проверки обращения
      appeal_info: false, // поиск вернул/не вернул информацию обращения
      appeal_result_title: null, // значения с поиска обращения
      appeal_name: null,
      appeal_status: null,
      appeal_id: null,
      appeal_date: null,
      appeal_text: null,
      appeal_comment: null,
      types_appeal: this.$store.state.appeals,
      appeal_list: this.$store.state.appeal_list
    };
  },
  components: { PageTitle },
  async asyncData (context) {
    await context.store.dispatch('appealsPage');
  },
  metaInfo () {
    return this.$seo('Электронная приемная');
  },
  methods: {
    appeal_submit () {
      let fd = new FormData();
      fd.append('appeal_type', this.appeal_type);
      fd.append('surname', this.surname);
      fd.append('name', this.name);
      fd.append('patronymic', this.patronymic);
      fd.append('recipient', this.selected_receiver);
      fd.append('city', this.city);
      fd.append('street', this.street);
      fd.append('home', this.home);
      fd.append('structure', this.building);
      fd.append('corps', this.corps);
      fd.append('flat', this.flat);
      fd.append('index', this.index);
      fd.append('email', this.email);
      fd.append('phone', this.tel);
      fd.append('about_question', this.question_title);
      fd.append('content_appeal', this.question);
      fd.append('other_goverment_department', this.before_question);

      // загрузка нескольких файлов
      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        fd.append('uploads[' + i + ']', file);
      }
      axios.post(this.$store.state.system_data.base_url + '/api/appeal', fd).then(response => {
        // alert('Ваше обращение отправлено! ID обращения ' + response.data.appeal_key)
        //проверить правильность текста
        alert('Ваше обращение отправлено! Номер (идентификатор) обращения отправлено на почту, указаная вами при заполнении обращения.');
        this.appeal_close();
      }).catch((error) => {
        console.error(error);
        alert('Произошла ошибка, попробуйте позднее.');
        this.appeal_close();
      });

    },
    processFile () {
      this.files = this.$refs.files.files;
    },
    checkAppeal () {
      this.loading = true;
      let fd = new FormData();
      fd.append('appeal_key', this.appeal_input);

      // указать url запроса

      axios.post(this.$store.state.system_data.base_url + '/api/appealkey', fd).then(response => {
        // console.log(response)
        if ((Object.keys(response.data)).length !== 0) {
          this.appeal_result_title = 'Результаты поиска обращения:';
          let info = response.data;
          this.appeal_name = info.surname + ' ' + info.name + ' ' + info.patronymic;
          this.appeal_status = info.status.text;
          this.appeal_date = info.created_at;
          this.appeal_id = info.appeal_key;
          this.appeal_text = info.about_question;
          this.appeal_comment = info.comment;
          this.appeal_info = true;
          this.loading = false;
        } else {
          this.appeal_result_title = 'Обращение с таким ID не найдено!';
          this.appeal_name = this.appeal_status = this.appeal_date = this.appeal_id = this.appeal_text = this.appeal_comment = null;
          this.appeal_info = true;
        }
      }).catch((error) => {
        console.log(error);
        alert('Обращение с таким ID не найдено!');
      });

    }
  }
};
</script>
